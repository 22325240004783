@import url("https://fonts.googleapis.com/css?family=Montserrat:500");
@import url("https://fonts.googleapis.com/css2?family=Special+Elite&display=swap");

/* RESET */

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

/* CUSTOM VALUES */

:root {
  --background-color: #282c34;
  --midground-element: #2c3e50;
  --foreground-element: #516170;
  --button-blue: #3498db;
  --button-blue-hover: #2980b9;
  --button-disabled: #515151;
  --gamecreator-grey: #dddddd;
}

/* PRE-DEFINED MEDIA QUERIES */
/* xs */
/* @media (min-width: 475px) {} */

/* sm */
/* @media (min-width: 640px) {} */

/* md */
/* @media (min-width: 768px) {} */

/* lg */
/* @media (min-width: 1024px) {} */

/* xl */
/* @media (min-width: 1280px) {} */

/* 2xl */
/* @media (min-width: 1536px) {} */

/* Default state, for screens wider than 767px */
.mobile-visible {
  display: block;
}

.mobile-hidden {
  display: block;
}

.summary-pager-button {
  display: none;
}

/* Media query for screens 767px wide or below */
@media (max-width: 767px) {
  .mobile-visible {
    display: block;
  }

  .mobile-hidden {
    display: none;
  }

  .summary-pager-button {
    display: block;
    margin: 0 auto;
  }

  .summary-pager-button:disabled {
    display: none; /* Hide the button when disabled */
  }
}

.container {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

/* xxs */
@media (min-width: 320px) {
  .container {
    max-width: 360px;
  }
  .App {
    max-width: 355px;
    padding: 0;
  }
}

/* xs */
@media (min-width: 475px) {
  .container {
    max-width: 465px;
  }
  .App {
    max-width: 455px;
    padding: 0;
  }
}

/* sm */
@media (min-width: 640px) {
  .container {
    max-width: 620px;
  }
  .App {
    max-width: 600px;
  }
}

/* md */
@media (min-width: 768px) {
  .container {
    max-width: 748px;
  }
  .App {
    max-width: 728px;
  }
}

/* lg */
@media (min-width: 1024px) {
  .container {
    max-width: 1004px;
  }
  .App {
    max-width: 984px;
  }
}

/* xl */
@media (min-width: 1280px) {
  .container {
    max-width: 1260px;
  }
  .App {
    max-width: 1240px;
  }
}

/* 2xl */
@media (min-width: 1536px) {
  .container {
    max-width: 1506px;
  }
  .App {
    max-width: 1496px;
  }
}

body {
  background-color: var(--background-color);
  color: white;
  font-family: "Special Elite", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #ecf0f1;
}

.container {
  flex-direction: column;
  display: flex;
  overflow-x: hidden;
  align-items: center;
  min-height: 400px;
  margin: 1.2rem auto;
  padding: 20px;
  background-color: var(--midground-element);
  color: #ecf0f1;
  border-radius: 12px;
}

h1,
h2,
h3,
h4 {
  text-align: center;
}

ul {
  list-style-type: none;
  margin: 1rem;
  font-weight: bold;
}

button {
  padding: 10px 20px;
  margin: 5px;
  font-size: 1rem;
  color: #fff;
  background-color: var(--button-blue);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

button:hover {
  background-color: var(--button-blue-hover);
}

button:disabled {
  background-color: var(--button-disabled);
  opacity: 0.6;
}

button:active {
  background-color: var(--foreground-element);
}

p {
  margin: 5px 0;
  text-align: center;
}

/* Adjust styles for smaller screens if needed */
@media (max-width: 600px) {
  .App {
    padding: 10px;
  }
}

.DebugContainer {
  font-family: Roboto, sans-serif;
  position: fixed;
  left: 100px; /* Adjust the left position as needed */
  top: 50px; /* Adjust the top position as needed */
  background-color: rgba(24, 3, 3, 0.8); /* Semi-transparent white background */
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Shadow for a floating effect */
  z-index: 1000; /* Ensure it appears above other elements */
  max-height: 80vh;
  width: 25vw;
  overflow-y: auto;
}

.TestElements {
  font-family: Roboto, sans-serif;
  position: fixed;
  right: 50px; /* Adjust the left position as needed */
  top: 50px; /* Adjust the top position as needed */
  background-color: rgba(24, 3, 3, 0.8); /* Semi-transparent white background */
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Shadow for a floating effect */
  z-index: 1000; /* Ensure it appears above other elements */
  max-height: 80vh;
  width: 25vw;
  overflow-y: auto;
}

/* HELP BOX */
.help-box {
  position: fixed;
  top: 1.5vw;
  left: 1.5vw;
  width: clamp(1.3rem, 2.5vw, 3rem);
  height: clamp(1.3rem, 2.5vw, 3rem);
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  z-index: 9999;
}

.help-icon {
  font-size: clamp(1rem, 2.5vw, 1.6rem);
  color: #000000;
}

.help-box-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  background-color: #516170;
  border-radius: 1rem;
  border: 1px solid #000000;
  padding: clamp(1rem, 1vw, 2rem);
  z-index: 9999;
  min-width: 300px;
  max-width: 95vw;
  max-height: 90vh;
  overflow: auto;
}

.icon-table {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
}

.help-text-cell {
  padding: 0.625rem;
  text-align: justify;
}

.card-icon-help {
  height: clamp(0.9rem, 1.5vw, 2.5rem);
  width: clamp(0.9rem, 1.5vw, 2.5rem);
  padding: clamp(0.1rem, 0.8vw, 1rem);
  border: 2px solid black;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.help-credits {
  font-size: clamp(0.8rem, 1.5vw, 1rem);
}

.book-description {
  max-width: 80ch;
}

.book-description {
  max-width: 120ch;
}

.ResponseEntry {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #333;
  border-radius: 5px;
  background-color: var(--foreground-element);
  color: #fff;
}

.ResponseEntry label {
  display: block;
  margin-bottom: 10px;
  color: #ccc; /* Label color */
}

.ResponseEntry select,
.ResponseEntry textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: var(--background-color);
  color: #fff;
  box-sizing: border-box;
  resize: none;
}

.ResponseEntry textarea {
  height: 150px;
}

.response-box {
  padding: 10px;
  margin: 5px;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.response-box:hover {
  background-color: #444;
}

.response-box:active {
  background-color: #555;
}

.summary-table {
  width: 80%;
  margin: 0 auto; /* Centers the table */
  border-collapse: collapse;
  color: #fff; /* Text color */
  background-color: var(--foreground-element); /* Background color */
}

/* xs */
@media (max-width: 475px) {
  .summary-table {
    width: 95%;
  }
}

.summary-table th,
.summary-table td {
  padding: 8px;
  border: 1px solid var(--background-color); /* Border color */
  text-align: left;
}

.summary-table th {
  background-color: var(--background-color); /* Header background color */
}

.summary-table tbody tr:nth-child(even) {
  background-color: var(--foreground-element); /* Even row background color */
}

/* Styling for the game creator container */
.game-creator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.2rem;
  background-color: var(--gamecreator-grey);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 20rem;
  margin-top: 3rem;
  color: black;
}

/* Styling for the input fields */
.game-creator input[type="text"] {
  width: 80%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.game-creator .ui-title {
  margin-bottom: 0;
}

.game-creator .ui-title h3 {
  margin-top: 5px;
  font-size: 2rem;
  font-weight: bold;
  color: #333;
}

.book-selection {
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
  justify-content: center;
}

/* RESPONSE STYLES */

.responses {
  list-style: none;
  display: grid;
  grid-auto-columns: 100%;
  grid-column-gap: 10px;
  grid-auto-flow: column;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  padding-left: 0;
  margin-top: 1.5rem;
}

.response {
  display: flex;
  padding: 20px;
  background: #516170;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 15%);
  scroll-snap-align: start;
  transition: all 0.2s;
  margin-bottom: 1.5rem;
}

.response-div {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.responses::-webkit-scrollbar {
  height: 12px;
}

.responses::-webkit-scrollbar-thumb,
.responses::-webkit-scrollbar-track {
  border-radius: 92px;
}

.responses::-webkit-scrollbar-thumb {
  background: var(--button-blue);
}

.responses::-webkit-scrollbar-track {
  background: var(--background-color);
}

/* xs */
@media (min-width: 475px) {
  .responses {
    grid-auto-columns: 100%;
    grid-column-gap: 30px;
  }
}

/* sm */
@media (min-width: 640px) {
  .responses {
    grid-auto-columns: 100%;
    grid-column-gap: 30px;
  }
}

/* md */
@media (min-width: 768px) {
  .responses {
    grid-auto-columns: 100%;
    grid-column-gap: 30px;
  }
}

/* lg */
@media (min-width: 1024px) {
  .responses {
    grid-auto-columns: calc(50% - 10px);
    grid-column-gap: 20px;
  }
}

/* xl */
@media (min-width: 1280px) {
  .responses {
    grid-auto-columns: calc(50% - 10px);
    grid-column-gap: 20px;
  }
}

/* 2xl */
@media (min-width: 1536px) {
  .responses {
    grid-auto-columns: calc(calc(100% / 3) - 20px);
    grid-column-gap: 30px;
  }
}

/* END OF RESPONSES */

.book-blurb {
  max-width: 100ch;
  padding: 20px;
  background: #516170;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 15%);
}

/* DEBUG SWITCH */
.debug-box {
  position: fixed;
  top: clamp(2rem, 4.5vw, 4.5vw);
  left: 1.5vw;
  width: clamp(1.3rem, 2.5vw, 3rem);
  height: clamp(1.3rem, 2.5vw, 3rem);
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  z-index: 9999;
}

.debug-icon {
  font-size: clamp(1rem, 2.5vw, 1.6rem);
  color: #000000;
}

.game-over-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  background-color: var(--foreground-element);
  border-radius: 1rem;
  border: 1px solid #000000;
  padding: 40px;
  z-index: 9998;
  min-width: 300px;
  max-width: 95vw;
  max-height: 90vh;
  overflow: auto;
}

.ready-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ready-checkbox {
  width: 25px;
  height: 25px;
}

.book-inventory {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #435261;
  border-radius: 1rem;
  border: 1px solid #000000;
  padding: clamp(1rem, 1vw, 2rem);
  z-index: 9999;
  min-width: 60%;
  min-height: 60%;
  overflow: auto;
  justify-content: center;
  align-items: center;
}

.highlight {
  background-color: #667685; /* Highlight color */
  font-weight: bold;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: var(--foreground-element);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-buttons button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-buttons button:first-child {
  background-color: #28a745; /* Green */
  color: white;
}

.modal-buttons button:last-child {
  background-color: #dc3545; /* Red */
  color: white;
}
